import { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { MdMail } from "react-icons/md";
import ReactRouterPrompt from "react-router-prompt";
import { toast } from "react-toastify";

import "../../stylesheets/Settings.scss";

import { Title } from "../../components/Title.jsx";
import LoadingIcon from "../../components/LoadingIcon";
import { RenderWarehouses } from "./RenderWarehouses.jsx";
import { RenderTruckers } from "./RenderTruckers.jsx";

import { FaEye, FaEyeSlash } from "react-icons/fa";
import { loadingAnimation } from "../../components/scripts/functions.js";

export const Settings = () => {
  const URL = process.env.REACT_APP_API_URL + "/user";
  const cookies = new Cookies();
  const token = cookies.get("token_user");
  const [userSettings, setUserSettings] = useState();
  const [loading, setLoading] = useState();
  const [toUpdate, setToUpdate] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [newDocsRequired, setNewDocsRequired] = useState([]);
  const [new7501Form, setNew7501Form] = useState([]);
  const [newBillingEmail, setNewBillingEmail] = useState([]);
  const [newDeliveryEmails, setNewDeliveryEmails] = useState([]);
  const [truckerError, setTruckerError] = useState();
  const [warehouseError, setWarehouseError] = useState();

  const [mainDocRequiredEmail, setMainDocRequiredEmail] = useState("");
  const [main7501Email, setMain7501Email] = useState("");
  const [mainBillingEmail, setMainBillingEmail] = useState("");
  const [mainDeliveryEmail, setMainDeliveryEmail] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const [sendingInfo, setSendingInfo] = useState(false);

  useEffect(() => {
    getUserSettings();
  }, []);
  useEffect(() => { }, [userSettings]);

  const getUserSettings = async () => {
    setLoading(true);
    try {
      const resp = await fetch(`${URL}/settings`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await resp.json();
      console.log(data);
      if (resp.status == 200) {
        const userSettings = data.user[0];

        setUserSettings(userSettings);
        setNewDocsRequired(
          userSettings.document_emails[0]?.cc_emails?.join(", ") || ""
        );
        setNew7501Form(
          userSettings._7501_emails[0]?.cc_emails?.join(", ") || ""
        );
        setNewBillingEmail(
          userSettings.invoice_emails[0]?.cc_emails?.join(", ") || ""
        );

        if (
          userSettings.delivery_order_mails[0]?.cc_emails &&
          userSettings.delivery_order_mails[0]?.cc_emails?.length > 0
        ) {
          setNewDeliveryEmails(
            userSettings.delivery_order_mails[0]?.cc_emails.join(", ") || ""
          );
        }

        setMainDocRequiredEmail(userSettings.document_emails[0]?.email || "");
        setMain7501Email(userSettings._7501_emails[0]?.email || "");
        setMainBillingEmail(userSettings.invoice_emails[0]?.email || "");

        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };


  const handleEmailUpdates = () => {
    setToUpdate([...toUpdate, "emails"]);
  };

  const reload = (b) => { };

  const receiveTruckers = (truckers) => {
    setUserSettings({ ...userSettings, truckers: truckers });
    setToUpdate([...toUpdate, "truckers"]);
  };

  const receiveWarehouses = (warehouses) => {
    setUserSettings({ ...userSettings, warehouses: warehouses });
    setToUpdate([...toUpdate, "warehouses"]);
  };

  const setNewEmail = (email) => {
    setUserSettings({ ...userSettings, email: email });
    setToUpdate([...toUpdate, "email"]);
  };

  const setMainEmail = (type, email) => {
    if (type === "documents_required") {
      setMainDocRequiredEmail(email);
      setToUpdate([...toUpdate, "documents_required_main_email"]);
    } else if (type === "7501_form") {
      setMain7501Email(email);
      setToUpdate([...toUpdate, "form_7501_main_email"]);
    } else if (type === "billing_invoice") {
      setMainBillingEmail(email);
      setToUpdate([...toUpdate, "billing_invoice_main_email"]);
    } else if (type === "delivery") {
      setMainDeliveryEmail(email);
      setToUpdate([...toUpdate, "delivery_main_email"]);
    }
  };

  const receiveEmails = () => {
    let user_emails = [];
    let docsObject = {
      table_number: 0,
    };

    let formObject = {
      table_number: 1,
    };

    let billingObject = {
      table_number: 2,
    };

    for (let i = 0; i < newDocsRequired.length; i++) {
      docsObject[`${i}`] = newDocsRequired[i];
      docsObject[`${i}`].replace("\n", "");
    }

    for (let i = 0; i < new7501Form.length; i++) {
      formObject[`${i}`] = new7501Form[i];
      formObject[`${i}`].replace("\n", "");
    }

    for (let i = 0; i < newBillingEmail.length; i++) {
      billingObject[`${i}`] = newBillingEmail[i];
      billingObject[`${i}`].replace("\n", "");
    }

    user_emails = [docsObject, formObject, billingObject];
    return user_emails;
  };

  const submitCompleteInfo = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    setTruckerError("");
    setWarehouseError("");

    if (
      !mainDocRequiredEmail ||
      !main7501Email ||
      !mainBillingEmail
    ) {
      toast.error("All main email addresses are required!");
      setIsSaving(false);
      return;
    }

    let docEmails =
      newDocsRequired && newDocsRequired.length > 0
        ? newDocsRequired.split(/[\n,]/)
        : null;
    let _7501Emails =
      new7501Form && new7501Form.length > 0 ? new7501Form.split(/[\n,]/) : null;
    let billingEmails =
      newBillingEmail && newBillingEmail.length > 0
        ? newBillingEmail.split(/[\n,]/)
        : null;
    let deliveryEmails =
      newDeliveryEmails && newDeliveryEmails.length > 0
        ? newDeliveryEmails.split(/[\n,]/)
        : null;

    let emailsArray = [
      {
        table_number: 0,
        email: mainDocRequiredEmail ? mainDocRequiredEmail : null,
        cc_emails: docEmails,
      },
      {
        table_number: 2,
        email: main7501Email ? main7501Email : null,
        cc_emails: _7501Emails,
      },
      {
        table_number: 1,
        email: mainBillingEmail ? mainBillingEmail : null,
        cc_emails: billingEmails,
      },
      {
        table_number: 3,
        cc_emails: deliveryEmails ? deliveryEmails : null,
      },
    ];

    const settingsObj = {};

    settingsObj["user_emails"] = emailsArray;

    if (toUpdate.includes("truckers")) {
      var truckers = userSettings.truckers.map((trucker) => {
        return {
          name: trucker.name,
          phone: trucker.phone,
          email: trucker?.trucker_emails[0]?.email,
          cc_emails: trucker?.trucker_emails[0]?.cc_emails,
        };
      });
      settingsObj["truckers"] = truckers;
    }

    if (toUpdate.includes("warehouses")) {
      var warehouses = userSettings.warehouses.map((warehouse) => {
        return {
          name: warehouse.name,
          phone: warehouse.phone,
          address: warehouse.address,
          city: warehouse.city,
          state: warehouse.state,
          zip: warehouse.zip,
          emails: warehouse.emails.map((email) => {
            return { name: email.name, email: email.email };
          }),
        };
      });
      settingsObj["warehouses"] = warehouses;
    }

    if (toUpdate.includes("email")) {
      settingsObj["email"] = userSettings.email;
    }

    if (toUpdate.includes("password")) {
      settingsObj["password"] = newPassword;
    }


    try {
      loadingAnimation(true);
      const resp = await fetch(`${URL}/settings/save`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
        body: JSON.stringify(settingsObj),
      });
      const res = await resp.json();

      console.log(resp);

      setToUpdate([]);
      setIsSaving(false);

      if (resp.status === 200) {
        loadingAnimation(false);
      }

      // Errors
      let curSettings = userSettings;

      if (
        res.truckers.errors?.length ||
        res?.truckers_message?.message?.includes(
          "Trucker is used in a delivery order"
        )
      ) {
        curSettings.truckers = res.truckers;
        setTruckerError(
          "Could not delete truckers because they're being used in a delivery order"
        );
      }
      if (
        res.warehouses.errors?.length ||
        res?.warehouse_message?.message?.includes(
          "Warehouse is used in a delivery order"
        )
      ) {
        curSettings.warehouses = res.warehouses;
        setWarehouseError(
          "Could not delete warehouses because they're being used in a delivery order"
        );
      }
      setUserSettings(curSettings);
    } catch (e) {
      console.log(e);
      loadingAnimation(false);
      toast.error("Something went wrong. Please, try again in a few minutes!");
    }
  };

  useEffect(() => { }, [newPassword]);

  return (
    <>
      <ReactRouterPrompt when={toUpdate?.length}>
        {({ isActive, onConfirm, onCancel }) => {
          if (isActive && toUpdate.length) {
            if (
              window.confirm(
                "You have unsaved changes. Are you sure you want to leave?"
              )
            ) {
              onConfirm();
            } else {
              onCancel();
            }
          }
        }}
      </ReactRouterPrompt>

      {loading || typeof userSettings === "undefined" ? (
        <>
          <Title title={sendingInfo ? "SENDING INFO..." : "Settings"} />
          <div id="settings" className="page-content">
            Loading...
          </div>
        </>
      ) : (
        <>
          <Title
            title={sendingInfo ? "SENDING INFO..." : "Settings"}
            links={[
              { link: "/", title: "Home" },
              { link: "/settings", title: "Settings" },
            ]}
          />
          <div id="settings" className="page-content">
            <form className="update-profile" onSubmit={submitCompleteInfo}>
              <div className="personal-information">
                <h3 className="section-title personal">Personal Information</h3>

                <div className="input-sections">
                  <div className="input-field account-name">
                    <div className="left">
                      <p className="field-title">Account Name</p>
                      <p className="field-small-text">
                        This field cannot be edited
                      </p>
                    </div>
                    <div className="right">
                      <div className="blocked-input">
                        <p>{userSettings.account_no}</p>
                      </div>
                    </div>
                  </div>

                  <div className="divisor"></div>

                  <div className="input-field account-adress">
                    <div className="left">
                      <p className="field-title">Account Email Address</p>
                      <p className="field-small-text">
                        Email address for global alerts and forgotten password
                      </p>
                    </div>
                    <div className="right">
                      <div className="allowed-input has-icon">
                        <input
                          type="email"
                          name="email"
                          id="email-input"
                          placeholder="Email Address"
                          value={userSettings.email}
                          onChange={(e) => {
                            e.preventDefault();
                            setNewEmail(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="divisor"></div>

                  <div className="input-field account-password">
                    <div className="left">
                      <p className="field-title">Password</p>
                      <p className="field-small-text">
                        Enter a new password to reset your password
                      </p>
                    </div>
                    <div className="right">
                      <div className="allowed-input has-icon">
                        <input
                          type={showPassword ? "text" : "password"}
                          name="password"
                          id="password-input"
                          placeholder="Password"
                          onChange={(e) => {
                            e.preventDefault();
                            setNewPassword(e.target.value);
                            setToUpdate([...toUpdate, "password"]);
                          }}
                        />
                        <div
                          className="input-icon"
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        >
                          {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="notifications">
                <h3 className="section-title personal">Notifications</h3>

                <div className="input-sections">
                  <div className="input-field docs-required">
                    <div className="left">
                      <p className="field-title">Documents Required</p>
                      <p className="field-small-text">
                        Email address for reminders to upload documents
                      </p>
                    </div>
                    <div className="right">
                      <div className="documents-input docs-required">
                        <div className="allowed-input has-icon main-email-bottom-margin">
                          <input
                            type="email"
                            name="email"
                            id="docs-required-input"
                            placeholder="Main Email Address"
                            value={mainDocRequiredEmail}
                            onChange={(e) => {
                              e.preventDefault();
                              setMainEmail(
                                "documents_required",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                        <div className="input">
                          <div className="input-icon">
                            <MdMail />
                          </div>
                          <textarea
                            id="docs-required-textarea"
                            className="docs-required"
                            placeholder="CC Emails"
                            value={newDocsRequired}
                            onInput={(e) => {
                              e.preventDefault();
                              setNewDocsRequired(e.target.value);
                              handleEmailUpdates(
                                e.target.value,
                                "document_emails"
                              );
                            }}
                            style={
                              newDocsRequired.length == 10
                                ? { pointerEvents: "none" }
                                : {}
                            }
                          ></textarea>
                        </div>
                        <div className="count">
                          <p>{newDocsRequired.split(", ").length}/10</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="divisor"></div>

                  <div className="input-field 7501Form">
                    <div className="left">
                      <p className="field-title">7501 Form</p>
                      <p className="field-small-text">
                        Email address for 7501 forms
                      </p>
                    </div>
                    <div className="right">
                      <div className="documents-input 7501Form">
                        <div className="allowed-input has-icon main-email-bottom-margin">
                          <input
                            type="email"
                            name="email"
                            id="form_7501_main_email"
                            placeholder="Main Email Address"
                            value={main7501Email}
                            onChange={(e) => {
                              e.preventDefault();
                              setMainEmail("7501_form", e.target.value);
                            }}
                          />
                        </div>
                        <div className="input">
                          <div className="input-icon">
                            <MdMail />
                          </div>
                          <textarea
                            id="7501Form-textarea"
                            className="7501Form"
                            placeholder="CC Emails"
                            value={new7501Form}
                            onInput={(e) => {
                              e.preventDefault();
                              setNew7501Form(e.target.value);
                              handleEmailUpdates(e.target.value, "7501");
                            }}
                          ></textarea>
                        </div>
                        <div className="count">
                          <p>{new7501Form.split(", ").length}/10</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="divisor"></div>

                  <div className="input-field billing">
                    <div className="left">
                      <p className="field-title">Billing Invoice</p>
                      <p className="field-small-text">
                        Email address for Billing Invoices
                      </p>
                    </div>
                    <div className="right">
                      <div className="documents-input billing">
                        <div className="allowed-input has-icon main-email-bottom-margin">
                          <input
                            type="email"
                            name="email"
                            id="billing_invoice_main_email"
                            placeholder="Main Email Address"
                            value={mainBillingEmail}
                            onChange={(e) => {
                              e.preventDefault();
                              setMainEmail("billing_invoice", e.target.value);
                            }}
                          />
                        </div>
                        <div className="input">
                          <div className="input-icon">
                            <MdMail />
                          </div>
                          <textarea
                            id="billing-textarea"
                            className="billing"
                            placeholder="CC Emails"
                            value={newBillingEmail}
                            onInput={(e) => {
                              e.preventDefault();
                              setNewBillingEmail(e.target.value);
                              handleEmailUpdates(e.target.value, "billing");
                            }}
                          ></textarea>
                        </div>
                        <div className="count">
                          <p>{newBillingEmail.split(", ").length}/10</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="input-field delivery">
                    <div className="left">
                      <p className="field-title">Delivery Order</p>
                      <p className="field-small-text">
                        Email addresses for Delivery Orders
                      </p>
                    </div>
                    <div className="right">
                      <div className="documents-input delivery">
                        <div className="input">
                          <div className="input-icon">
                            <MdMail />
                          </div>
                          <textarea
                            id="delivery-emails"
                            className="delivery-emails"
                            placeholder="Delivery Emails"
                            value={newDeliveryEmails}
                            onInput={(e) => {
                              e.preventDefault();
                              setNewDeliveryEmails(e.target.value);
                              handleEmailUpdates(e.target.value, "delivery");
                            }}
                          ></textarea>
                        </div>
                        {newDeliveryEmails.length > 0 ? (
                          <div className="count">
                            <p>{newDeliveryEmails.split(", ").length}/10</p>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <RenderTruckers
                truckers={userSettings.truckers}
                sendTruckers={receiveTruckers}
              />
              {truckerError ? (
                <p className="error-msg">{truckerError}</p>
              ) : null}
              <RenderWarehouses
                warehouses={userSettings?.warehouses}
                reloadComponent={reload}
                userId={userSettings.id}
                sendWarehouses={receiveWarehouses}
              />
              {warehouseError ? (
                <p className="error-msg">{warehouseError}</p>
              ) : null}
              {toUpdate.length > 0 ? (
                <div className="alert">You have several unsaved changes.</div>
              ) : null}
              <button type="submit">
                {isSaving ? <LoadingIcon /> : "Save"}
              </button>
            </form>
          </div>
        </>
      )}
    </>
  );
};
export default Settings;
