import { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import Select from "react-select";
import LoadingIcon from "../../components/LoadingIcon";
import { toast } from "react-toastify";
import { DeliveryOrderWarehouse } from "./DeliveryOrderWarehouse";
import { DeliveryOrderTrucker } from "./DeliveryOrderTrucker";
import "../../stylesheets/Modal.scss";
import "../../stylesheets/Warehouse.scss";


export const DeliveryOrder = ({ x, id, handleDelete, handleNewDelete }) => {
  const cookies = new Cookies();
  const token = cookies.get("token_user");
  const URL = process.env.REACT_APP_API_URL + "/user";

  const [selectedTrucker, setSelectedTrucker] = useState(0);
  const [selectedWarehouse, setSelectedWarehouse] = useState(0);
  const [selectedContainers, setSelectedContainers] = useState([]);
  const [newReferenceNumber, setNewReferenceNumber] = useState("");
  const [newNote, setNewNote] = useState("");
  const [openDO, setOpenDO] = useState(true);

  const [masterBillLading, setMasterBillLading] = useState([]);
  const [houseBillLading, setHouseBillLading] = useState([]);

  const [selectedMasterBillLading, setSelectedMasterBillLading] = useState([]);
  const [selectedHouseBillLading, setSelectedHouseBillLading] = useState([]);

  const [billLading, setBillLading] = useState();
  const [selectedBills, setSelectedBills] = useState([]);

  const [shipment, setShipment] = useState();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    trucker: false,
    warehouse: false,
    container: false,
  });
  const [error, setError] = useState("");
  const [saveLoading2, setSaveLoading2] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isNew, setIsNew] = useState(true);

  const [warehouseModalIsOpen, setWarehouseModalIsOpen] = useState(false);
  const [truckerModalIsOpen, setTruckerModalIsOpen] = useState(false);

  const openWarehouseModal = () => {
    setWarehouseModalIsOpen(true);
  };

  const closeWarehouseModal = () => {
    setWarehouseModalIsOpen(false);
  };

  const openTruckerModal = () => {
    setTruckerModalIsOpen(true);
  };

  const closeTruckerModal = () => {
    setTruckerModalIsOpen(false);
  };

  const getShipmentData = async (resetSelection = true) => {
    setLoading(true);
    try {
      const resp = await fetch(`${URL}/shipments/details/${id}`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await resp.json();
      setShipment(data?.shipment);

      // Prefill data
      if (data?.shipment?.containers?.length) {
        if (data?.shipment?.containers?.length < 2) {
          setSelectedContainers([
            { container_id: data?.shipment?.containers[0]?.id },
          ]);
        }
        if (data.shipment.delivery_orders[x]) {
          setIsNew(false);
          setNewReferenceNumber(
            data?.shipment?.delivery_orders[x]?.reference_number
          );

          // Only set selected trucker and warehouse if resetSelection is true
          if (resetSelection) {
            if (data?.shipment?.delivery_orders[x]?.trucker_id) {
              setSelectedTrucker(data?.shipment?.delivery_orders[x]?.trucker_id);
            }
            if (data?.shipment?.delivery_orders[x]?.warehouse_id) {
              setSelectedWarehouse(
                data?.shipment?.delivery_orders[x]?.warehouse_id
              );
            }
          }

          if (
            data?.shipment?.delivery_orders[x]?.delivery_order_containers &&
            data?.shipment?.containers?.length > 1
          ) {
            const tempContainers =
              data?.shipment?.delivery_orders[x]?.delivery_order_containers;
            setSelectedContainers(
              tempContainers.map((i) => ({
                container_id: i?.container_id,
                container_no: i?.container?.container_no,
              }))
            );
          }
          if (data?.shipment?.delivery_orders[x]?.note !== "No note provided") {
            setNewNote(data?.shipment?.delivery_orders[x]?.note);
          }
          if (data?.shipment?.delivery_orders[x]?.bill_ladings?.length > 0) {
            let temp_master = [];
            let temp_house = [];

            let landing = [];
            let landingSelected = [];
            data?.shipment?.delivery_orders[x]?.bill_ladings?.forEach((bill) => {
              landing.push(bill);
              landingSelected.push(bill?.id);
              if (bill?.is_master_bill_lading === 1) {
                temp_master.push({ label: bill?.bill_lading, value: bill?.id });
              } else if (bill?.is_master_bill_lading === 0) {
                temp_house.push({ label: bill?.bill_lading, value: bill?.id });
              }
            });
            setSelectedHouseBillLading(temp_house);
            setSelectedMasterBillLading(temp_master);

            setBillLading(landing);
            setSelectedBills(landingSelected);
          }
        } else {
          setNewReferenceNumber(data?.shipment?.customer_reference_no);
        }
      }

      setLoading(false);
    } catch (e) {
      console.log(e);
      alert("Server not responding, please contact us.");
    }
  };

  const handleNewContainer = (containers) => {
    setErrors({ ...errors, containers: false });
    setSelectedContainers(
      containers.map((container) => ({
        container_id: container?.value,
        container_no: container?.label,
      }))
    );
  };

  const removedAllContainers = () => {
    setSelectedContainers([]);
  };

  const handleNewWarehouse = (newWarehouse) => {
    console.log('entro en new warehouse ', newWarehouse);
    setSelectedWarehouse(newWarehouse.id); // Set selected warehouse first
    setShipment((prev) => ({
      ...prev,
      user: {
        ...prev.user,
        warehouses: [newWarehouse, ...prev.user.warehouses],
      },
    }));
    // Fetch updated shipment data without overwriting the selected state
    getShipmentData(false); // Adjust getShipmentData to not reset the state
  };

  const handleNewTrucker = (newTrucker) => {
    console.log('entro en new trucker', newTrucker);
    setSelectedTrucker(newTrucker.id); // Set selected trucker first
    setShipment((prev) => ({
      ...prev,
      user: {
        ...prev.user,
        truckers: [newTrucker, ...prev.user.truckers],
      },
    }));
    // Fetch updated shipment data without overwriting the selected state
    getShipmentData(false); // Adjust getShipmentData to not reset the state
  };

  const formatContainers = (dbContainers) => {
    const deliveryOrderList = new Set(); // Use a Set to ensure unique container IDs
    const rawContainers = []; // raw ids to be formatted

    const validContainers = []; // formatted actual ids to map

    // Collect unique container IDs from shipment delivery orders
    shipment.delivery_orders.forEach((d) => {
      d.delivery_order_containers.forEach((c) => {
        if (c?.container_id) {
          deliveryOrderList.add(c.container_id);
        }
      });
    });

    // Format raw containers
    dbContainers.forEach((c) => {
      rawContainers.push({ label: c?.container_no, value: c?.id });
    });

    // If there are no delivery orders, return all raw containers
    if (deliveryOrderList.size === 0) {
      return rawContainers;
    }

    // Filter raw containers to include only those in the delivery order list
    rawContainers.forEach((r) => {
      if (deliveryOrderList.has(r.value)) {
        validContainers.push(r);
      }
    });

    // Return unique valid containers
    return validContainers;
  };

  const sendDeliveryOrder = async (e) => {
    e.preventDefault();
    const shipmentId = id;
    const doId = shipment?.delivery_orders[x]?.id;

    //setSendLoading(true);

    try {
      const response = await fetch(
        `${URL}/shipments/${shipmentId}/delivery_order/${doId}/send`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json",
          },
        }
      );
      const result = await response.json();

      if (response.status === 200) {
        console.log("Delivery Order sent successfully");
        toast.success("Delivery Order sent successfully!");
      } else {
        console.error("Error sending Delivery Order:", result.errors[0]);
        toast.error("Error sending Delivery Order. Please try again later.");
      }
    } catch (error) {
      console.error("Failed to send Delivery Order:", error);
      toast.error("Something went wrong. Please, try again in a few minutes!");
    } finally {
      //setSendLoading(false);
    }
  };

  const updateOrder = async (e) => {
    e.preventDefault();
    setSaveLoading2(true);

    var tempContainers = selectedContainers;

    var data = {
      trucker_id: selectedTrucker,
      warehouse_id: selectedWarehouse,
      reference_number: newReferenceNumber,
      note: newNote,
      containers: tempContainers.map((container) => ({
        container_id: container?.container_id,
      })),
      bill_ladings: billLading,
    };

    // Handle Errors
    var curErrors = {
      trucker: false,
      warehouse: false,
      container: false,
      bills: false,
    };
    var hasError = false;

    if (!data?.trucker_id) {
      curErrors.trucker = true;
      hasError = true;
    }
    if (!data?.warehouse_id) {
      curErrors.warehouse = true;
      hasError = true;
    }
    if (!data?.containers || data?.containers?.length < 1) {
      curErrors.containers = true;
      hasError = true;
    }
    if (!billLading || billLading?.length <= 0) {
      curErrors.bills = true;
      hasError = true;
    }
    setErrors(curErrors);

    if (hasError) {
      setSaveLoading2(false);
      return;
    }

    // if (data.note == null) {
    //   data.note = "No note provided";
    // }

    try {
      const resp = await fetch(
        `${URL}/shipments/${id}/delivery_order/${shipment?.delivery_orders[x]?.id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json",
          },
          body: JSON.stringify(data), //each individual file object
        }
      );
      const fin = await resp.json();

      if (resp.status == 200) {
        setLoading(false);
        setSaveLoading2(false);
        console.log(`Delivery Order succesfuly uploaded`);
      } else {
        setLoading(false);
        setSaveLoading2(false);
        setError(fin?.errors[0]);
        console.log(
          `There was an error uploading the Delivery Order, try again`
        );
        toast.error(
          "Something went wrong. Please, try again in a few minutes!"
        );
      }
    } catch (e) {
      toast.error("Something went wrong. Please, try again in a few minutes!");
    }
  };

  const deleteOrder = async (e) => {
    e.preventDefault();
    setDeleteLoading(true);

    if (shipment.delivery_orders[x]) {
      try {
        const resp = await fetch(
          `${URL}/shipments/${id}/delivery_order/${shipment?.delivery_orders[x]?.id}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-type": "application/json",
            },
          }
        );

        const fin = await resp.json();

        if (resp?.status == 200) {
          handleDelete();
          setDeleteLoading(false);
        } else {
          setDeleteLoading(false);
          toast.error(
            "Something went wrong. Please, try again in a few minutes!"
          );
        }
      } catch (e) {
        toast.error(
          "Something went wrong. Please, try again in a few minutes!"
        );
      }
    } else {
      handleNewDelete();
      setDeleteLoading(false);
    }
  };

  const [doError, setDoError] = useState(false);

  const newOrder = async (e) => {
    e.preventDefault();
    setSaveLoading2(true);

    var data = {
      trucker_id: selectedTrucker,
      warehouse_id: selectedWarehouse,
      reference_number: newReferenceNumber,
      note: newNote,
      containers: selectedContainers,
      bill_ladings: billLading,
    };

    // Handle Errors
    var curErrors = {
      trucker: false,
      warehouse: false,
      container: false,
      bills: false,
    };
    var hasError = false;

    if (!data?.trucker_id) {
      curErrors.trucker = true;
      hasError = true;
    }
    if (!data?.warehouse_id) {
      curErrors.warehouse = true;
      hasError = true;
    }
    if (!data?.containers || data?.containers?.length < 1) {
      curErrors.containers = true;
      hasError = true;
    }
    if (!billLading || billLading?.length <= 0) {
      curErrors.bills = true;
      hasError = true;
    }
    setErrors(curErrors);

    if (hasError) {
      setSaveLoading2(false);
      return;
    }

    try {
      const resp = await fetch(`${URL}/shipments/${id}/delivery_order`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const fin = await resp.json();
      getShipmentData(false); // Fetch updated data without resetting selection

      if (resp?.status == 200) {
        setIsNew(false);
        setSaveLoading2(false);
        console.log(`Delivery Order successfully created!`);
        setDoError(false);
      } else {
        setSaveLoading2(false);
        toast.error(
          "Something went wrong. Please, try again in a few minutes!"
        );
        setDoError(true);
      }
    } catch (e) {
      toast.error("Something went wrong. Please, try again in a few minutes!");
    }
  };

  const handleMasterBillOfLading = (e) => {
    const temp = [];
    e.forEach((bill) => {
      temp.push(bill?.value);
    });

    setSelectedMasterBillLading(e);
  };
  const handleHouseBillOfLading = (e) => {
    const temp = [];
    e.forEach((bill) => {
      temp.push(bill?.value);
    });
    setSelectedHouseBillLading(e);
  };
  useEffect(() => {
    let final = [...selectedMasterBillLading, ...selectedHouseBillLading];
    const finalBillArray = final.map((bill) => bill?.value);
    setBillLading(finalBillArray);
  }, [selectedMasterBillLading, selectedHouseBillLading]);

  const handleDOTab = () => {
    if (openDO == false) {
      setOpenDO(true);
    } else {
      setOpenDO(false);
    }
  };

  useEffect(() => {
    getShipmentData();
  }, []);

  return (
    <>
      <Modal isOpen={warehouseModalIsOpen} onClose={closeWarehouseModal}>
        <DeliveryOrderWarehouse
          index={null}
          warehouseInfo={{}}
          updateWarehouse={() => { }}
          deleteWarehouse={() => { }}
          isNew={true}
          emailError={false}
          closeModal={closeWarehouseModal}
          addWarehouse={handleNewWarehouse}
          setSelectedWarehouse={setSelectedWarehouse}
        />
      </Modal>

      <Modal isOpen={truckerModalIsOpen} onClose={closeTruckerModal}>
        <DeliveryOrderTrucker
          tid={null}
          isNew={true}
          addTrucker={handleNewTrucker}
          closeModal={closeTruckerModal}
        />
      </Modal>

      {typeof shipment === "undefined" || loading ? (
        <div className="delivery-order-container">
          <h3>Loading...</h3>
        </div>
      ) : (
        <div className="delivery-order-container">
          <div className="tab-header" onClick={handleDOTab}>
            <p className="title">Delivery Order #{x + 1}</p>
            {openDO ? (
              <span className="arrow">
                <svg width="10.317" height="7.317" viewBox="0 0 10.317 7.317">
                  <g transform="translate(1.408 1.408)">
                    <line
                      y1="4.5"
                      x2="3.75"
                      fill="none"
                      stroke="#303030"
                      strokeLinecap="round"
                      strokeWidth="2"
                    />
                    <line
                      x1="3.75"
                      y1="4.5"
                      transform="translate(3.75)"
                      fill="none"
                      stroke="#303030"
                      strokeLinecap="round"
                      strokeWidth="2"
                    />
                  </g>
                </svg>
              </span>
            ) : (
              <span className="arrow">
                <svg width="10.317" height="7.317" viewBox="0 0 10.317 7.317">
                  <g transform="translate(-188.092 -344.092)">
                    <line
                      x2="3.75"
                      y2="4.5"
                      transform="translate(189.5 345.5)"
                      fill="none"
                      stroke="#303030"
                      strokeLinecap="round"
                      strokeWidth="2"
                    />
                    <line
                      x1="3.75"
                      y2="4.5"
                      transform="translate(193.25 345.5)"
                      fill="none"
                      stroke="#303030"
                      strokeLinecap="round"
                      strokeWidth="2"
                    />
                  </g>
                </svg>
              </span>
            )}
          </div>
          <form
            className="delivery-order_form"
            style={openDO ? { display: "flex" } : { display: "none" }}
          >
            <div
              className={`input-box select-trucker ${errors.trucker ? "error" : ""
                }`}
            >
              <label>
                <p>Trucker</p>
              </label>
              {selectedTrucker ? (
                <Select
                  options={[
                    { value: "add-new", label: "Add New Trucker" },
                    ...shipment.user.truckers
                      .filter((trucker) => trucker.status == "1")
                      .map((trucker) => ({
                        value: trucker.id,
                        label: trucker.name,
                      })),
                  ]}
                  onChange={(e) => {
                    if (e.value === "add-new") {
                      openTruckerModal();
                    } else {
                      setErrors({ ...errors, trucker: false });
                      setSelectedTrucker(e.value);
                    }
                  }}
                  defaultValue={{
                    label: shipment.user.truckers.filter(
                      (trucker) => trucker.id == selectedTrucker
                    )[0]?.name,
                    selectedTrucker,
                  }}
                />
              ) : (
                <Select
                  options={[
                    { value: "add-new", label: "Add New Trucker" },
                    ...shipment.user.truckers
                      .filter((trucker) => trucker.status == "1")
                      .map((trucker) => ({
                        value: trucker.id,
                        label: trucker.name,
                      })),
                  ]}
                  onChange={(e) => {
                    if (e.value === "add-new") {
                      openTruckerModal();
                    } else {
                      setErrors({ ...errors, trucker: false });
                      setSelectedTrucker(e.value);
                    }
                  }}
                />
              )}
            </div>

            <div
              className={`input-box select-warehouse ${errors.warehouse ? "error" : ""
                }`}
            >
              <label>
                <p>Warehouse</p>
              </label>
              {selectedWarehouse ? (
                <Select
                  options={[
                    { value: "add-new", label: "Add New Warehouse" },
                    ...shipment.user.warehouses
                      .filter((warehouse) => warehouse.status == "1")
                      .map((warehouse) => ({
                        value: warehouse.id,
                        label: warehouse.name,
                      })),
                  ]}
                  onChange={(e) => {
                    if (e.value === "add-new") {
                      openWarehouseModal();
                    } else {
                      setErrors({ ...errors, warehouse: false });
                      setSelectedWarehouse(e.value);
                    }
                  }}
                  defaultValue={{
                    label: shipment.user.warehouses.filter(
                      (warehouse) => warehouse.id == selectedWarehouse
                    )[0]?.name,
                    selectedWarehouse,
                  }}
                />
              ) : (
                <Select
                  options={[
                    { value: "add-new", label: "Add New Warehouse" },
                    ...shipment.user.warehouses
                      .filter((warehouse) => warehouse.status == "1")
                      .map((warehouse) => ({
                        value: warehouse.id,
                        label: warehouse.name,
                      })),
                  ]}
                  onChange={(e) => {
                    if (e.value === "add-new") {
                      openWarehouseModal();
                    } else {
                      setErrors({ ...errors, warehouse: false });
                      setSelectedWarehouse(e.value);
                    }
                  }}
                />
              )}
            </div>

            {shipment?.bill_lading?.filter(
              (bill) => bill?.is_master_bill_lading == 1
            ).length > 0 && (
                <div
                  className={`input-box select-container ${errors.bills ? "error" : ""
                    }`}
                >
                  <label>
                    <p>Master Bill of Lading</p>
                  </label>
                  <Select
                    isMulti={true}
                    options={shipment?.bill_lading
                      ?.filter((bill) => bill?.is_master_bill_lading == 1)
                      .map((bill) => ({
                        value: bill.id,
                        label: bill.bill_lading,
                      }))}
                    defaultValue={selectedMasterBillLading.map((i) => ({
                      label: i.label,
                      value: i.value,
                    }))}
                    onChange={(e) => {
                      if (e.length > 0) {
                        handleMasterBillOfLading(e);
                      } else {
                        handleMasterBillOfLading(e);
                      }
                    }}
                  />
                </div>
              )}

            {shipment?.bill_lading?.filter(
              (bill) => bill?.is_master_bill_lading == 0
            ).length > 0 && (
                <div
                  className={`input-box select-container ${errors.bills ? "error" : ""
                    }`}
                >
                  <label>
                    <p>House Bill of Lading</p>
                  </label>
                  <Select
                    isMulti={true}
                    options={shipment?.bill_lading
                      ?.filter((bill) => bill?.is_master_bill_lading == 0)
                      .map((bill) => ({
                        value: bill.id,
                        label: bill.bill_lading,
                      }))}
                    defaultValue={selectedHouseBillLading.map((i) => ({
                      label: i.label,
                      value: i.value,
                    }))}
                    onChange={(e) => {
                      if (e.length > 0) {
                        handleHouseBillOfLading(e);
                      } else {
                        handleHouseBillOfLading(e);
                      }
                    }}
                  />
                </div>
              )}

            <div
              className={`input-box select-container ${errors.containers ? "error" : ""
                }`}
            >
              <label>
                <p>
                  {shipment.containers.length > 1 ? "Containers" : "Container"}
                </p>
              </label>
              {shipment.containers.length > 1 ? (
                <Select
                  isMulti={true}
                  options={formatContainers(shipment.containers)}
                  defaultValue={selectedContainers.map((i) => ({
                    label: i.container_no,
                    value: i.container_id,
                  }))}
                  onChange={(e) => {
                    if (e.length > 0) {
                      console.log(e);
                      handleNewContainer(e);
                    } else {
                      removedAllContainers();
                    }
                  }}
                />
              ) : (
                <input
                  type="text"
                  value={shipment.containers[0]?.container_no}
                  disabled={true}
                  className="text-input"
                />
              )}
            </div>
            <div className="input-box reference_number">
              <label>
                <p>Reference Number</p>
              </label>
              <input
                type="text"
                placeholder={shipment.customer_reference_no}
                value={newReferenceNumber}
                onChange={(e) => setNewReferenceNumber(e.target.value)}
                className="text-input"
              />
            </div>
            <div className="delivery-order_note">
              <label>
                <p>Notes</p>
              </label>
              <textarea
                name="delviery_note"
                id="delviery_note"
                value={newNote}
                onChange={(e) => {
                  setNewNote(e.target.value);
                }}
              ></textarea>
            </div>
            {doError && (
              <p
                className="error-message"
                style={{
                  background: "var(--global-red)",
                  border: "1px solid var(--red)",
                  color: "var(--red)",
                  padding: "6px 12px",
                  borderRadius: "5px",
                }}
              >
                This shipment is still missing a container number. Please wait
                for that information to be available or contact{" "}
                <a
                  href="mailto:info@atlanticbrokers.com"
                  style={{ textDecoration: "underline" }}
                >
                  info@atlanticbrokers.com
                </a>
              </p>
            )}
            <div className="buttons">
              <button className="delete" onClick={(e) => deleteOrder(e)}>
                {deleteLoading ? <LoadingIcon /> : "Delete"}
              </button>
              <button
                type="submit"
                className="submit"
                onClick={(e) => {
                  isNew ? newOrder(e) : updateOrder(e);
                }}
              >
                {saveLoading2 ? <LoadingIcon /> : "Save"}
              </button>
              {shipment?.delivery_orders[x]?.approved && (
                <button
                  type="submit"
                  className="submit"
                  onClick={sendDeliveryOrder}
                >
                  {sendLoading ? <LoadingIcon /> : "Send"}
                </button>
              )}
            </div>
          </form>
          <p className="error-message">{error}</p>
        </div>
      )}
    </>
  );
};

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;
  return (
    <div className="modal-overlay">
      <div className="modal">
        <button onClick={onClose} className="close-modal">
          Close
        </button>
        {children}
      </div>
    </div>
  );
};
